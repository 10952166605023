export const PetType = [
  { value: "Not Applicable" },
  { value: "Terrestrial Tarantula" },
  { value: "Arboreal Tarantula" },
  { value: "Spider" },
  { value: "Scorpion" },
  { value: "Gecko" },
  { value: "Snake" },
  { value: "Rodent" },
  { value: "Fish" },
];
