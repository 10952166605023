import React from "react";
import Navbar from "./components/Navbar/Navbar";
import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "./pages/Services";
import Products from "./pages/Products";
import ContactUs from "./pages/ContactUs";
// import PetViewerM1 from "./pages/Products/PetViewerM1";
import Login from "./pages/Login";
import UnderDevelopment from "./pages/UnderDevelopment";
import { ThemeProvider } from "@mui/material";
import CustomTheme from "./theme/CustomTheme";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import SingleMicroFeederTray from "./pages/Order/SingleMicroFeederTray";
import DoubleMicroFeederTray from "./pages/Order/DoubleMicroFeederTray";
import PetViewerM1 from "./pages/Order/PetViewerM1";
import TwoFiveGallonLid from "./pages/Order/TwoFiveGallonLid";
import FiveFiveGallonLid from "./pages/Order/FiveFiveGallonLid";
import VineHolder from "./pages/Order/VineHolder";
import Vision from "./pages/Vision";
import PetGallery from "./pages/PetGallery";
import AntiInnovation from "./pages/AntiInnovation";
import AuthProvider from "./security/AuthProvider";

const firebaseConfig = {
  apiKey: "AIzaSyCBKdE1Yb79TsmDQXZtW5C-mvwUqBQxKZk",
  authDomain: "nephewtechnologies-9c520.firebaseapp.com",
  projectId: "nephewtechnologies-9c520",
  storageBucket: "nephewtechnologies-9c520.appspot.com",
  messagingSenderId: "418309766209",
  appId: "1:418309766209:web:32b3a65354a55fa796dac0",
  measurementId: "G-XXK3JPXMYJ",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={CustomTheme}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/underdevelopment" element={<UnderDevelopment />} />
            <Route path="/our-vision" element={<Vision />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/order/single-micro-feeder"
              element={<SingleMicroFeederTray />}
            />
            <Route
              path="/order/double-micro-feeder"
              element={<DoubleMicroFeederTray />}
            />
            <Route path="/order/petviewer-m1" element={<PetViewerM1 />} />
            <Route
              path="/order/2-5-gallon-lid"
              element={<TwoFiveGallonLid />}
            />
            <Route
              path="/order/5-5-gallon-lid"
              element={<FiveFiveGallonLid />}
            />
            <Route path="/order/vine-holder" element={<VineHolder />} />
            <Route path="/gallery" element={<UnderDevelopment />} />
            <Route path="/anti-innovation" element={<AntiInnovation />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
}

// /products/petviewer-m1

export default App;
/*
<Route path="/products/petviewer-m1" element={<PetViewerM1 />} />
            <Route
              path="/products/petviewer-m1-video"
              element={<PetViewerM1Video />}
            />
*/
