import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Slideshow.css";

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  height: "95vh",
};

const slideImages = [
  {
    url: "./images/Slideshow/DSC_0889.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0876.JPG",
  },
  {
    url: "./images/Slideshow/DSC_1084.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0917.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0911.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0913.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0891.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0887.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0828.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0903.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0907.JPG",
  },
  {
    url: "./images/Slideshow/DSC_1082.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0830.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0946.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0937.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0969.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0896.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0899.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0887.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0908.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0922.JPG",
  },
  {
    url: "./images/Slideshow/DSC_0938.JPG",
  },
  {
    url: "./images/Slideshow/DSC_1082.JPG",
  },
];

const Slideshow = () => {
  return (
    <div className="slide-container">
      <Slide arrows={false}>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div
              className="slides"
              style={{ backgroundImage: `url(${slideImage.url})` }}
            ></div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default Slideshow;
