import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../../theme/CustomTheme";

function Description({ description }) {
  return (
    <Box width="inherit">
      {description.map((paragraph, index) => {
        return (
          <Typography
            paragraph
            key={index}
            width="inherit"
            variant="body"
            sx={{ color: Colors.black }}
          >
            {paragraph.p}
          </Typography>
        );
      })}
    </Box>
  );
}

export default Description;
