import React from "react";
import "../App.css";
import ProductCards from "../components/Cards/ProductCards";
import Footer from "../components/Footer/Footer";

export default function Products() {
  return (
    <>
      <ProductCards></ProductCards>
      <Footer></Footer>
    </>
  );
}
