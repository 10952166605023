import { Card, Typography, CardHeader, CardContent } from "@mui/material";
import React from "react";
import { Colors } from "../../theme/CustomTheme";

function VisionStatement() {
  const centerDiv = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    paddingTop: "5%",
    paddingBottom: "5%",
    background: Colors.dark_gray,
    minHeight: "55vh",
  };

  return (
    <>
      <div style={centerDiv}>
        <Card
          sx={{
            maxWidth: "90%",
            maxHeight: "100%",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h3" sx={{ color: Colors.black }}>
                Purpose, Vision, and Application
              </Typography>
            }
          />
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              Our purpose is to develop customizable and modular solutions to
              problems that have been neglected, outright unidentified, or
              underserved.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              Our tools for solving problems are proprietarily developed
              software, electronic components, micro-computers, computer
              assisted designs, and thermoplastics three dimensional printing.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              We bring an array of development methodologies to solve problems
              and develop solutions that are satisfactory for our customers.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              Currently, we are focused on developing products for small pets.
              Our focus will evolve over time as we become established.
              {/* We are
              open and excited to apply our expertise to non-pet related
              problems. */}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default VisionStatement;
