import React, { useContext, useState } from "react";
import { createContext } from "react";
import validator from "validator";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
  const [isAuthenticated, setAuthenticated] = useState(false);

  function login(email, password) {
    if (
      email === "ejnephew@yahoo.com" &&
      password === "password" &&
      validateEmail(email)
    ) {
      setAuthenticated(true);
      return true;
    } else {
      setAuthenticated(false);
      return false;
    }
  }

  function validateEmail(email) {
    return validator.isEmail(email);
  }

  function logout() {
    setAuthenticated(false);
  }

  // setInterval(() => setNumber(number + 1), 3000);

  const valueToBeShared = {
    isAuthenticated,
    setAuthenticated,
    login,
    logout,
    validateEmail,
  };

  return (
    <AuthContext.Provider value={valueToBeShared}>
      {children}
    </AuthContext.Provider>
  );
}
