import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../../theme/CustomTheme";

function Features({ features }) {
  return (
    <Box width="inherit">
      {features.map((feature, index) => {
        return (
          <Typography
            paragraph
            key={index}
            width="inherit"
            variant="body"
            sx={{ color: Colors.black }}
          >
            <strong>{feature.feature}</strong>
          </Typography>
        );
      })}
    </Box>
  );
}

export default Features;
