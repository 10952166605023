import React from "react";
import VisionStatement from "../components/VisionStatement/VisionStatement";

function Vision() {
  return (
    <>
      <VisionStatement></VisionStatement>
    </>
  );
}
/* <Footer></Footer> */
export default Vision;
