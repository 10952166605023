import axios from "axios";

/*** 
 URLS
 https://nssnotify.org
 http://localhost:8082
 */
const apiClient = axios.create({
  baseURL: "https://nssnotify.org",
});

export async function sendEmail(emailObject) {
  try {
    let res = await apiClient({
      url: "/contact-us/send-email?version=1",
      method: "post",
      data: {
        name: emailObject.name,
        email: emailObject.email,
        motivation: emailObject.motivation,
        petType: emailObject.petType,
        message: emailObject.message,
        subject: emailObject.subject,
      },
      timeout: 8000,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    return err;
  }
}

export async function test() {
  try {
    let res = await apiClient({
      url: "/test-success",
      method: "get",
      timeout: 8000,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status == 200) {
      console.log(res.status);
    }
    return res.data;
  } catch (err) {
    console.error(err);
  }
}
