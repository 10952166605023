import React from "react";
import "../App.css";
import UnderDevelopment from "./UnderDevelopment";

export default function Services() {
  return (
    <>
      <UnderDevelopment></UnderDevelopment>
    </>
  );
}
