import React from "react";
import "../App.css";
import Cards from "../components/Cards/ProductCards";
import TopHomeSection from "../components/TopHomeSection/TopHomeSection";
import Footer from "../components/Footer/Footer";
import Vision from "./Vision";

function Home() {
  return (
    <>
      <TopHomeSection />
      {/* <Cards /> */}
      <Vision />
      <Footer></Footer>
    </>
  );
}

export default Home;
