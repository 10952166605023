import React from "react";
import ProductCheckout from "../../components/ProductCheckout/ProductCheckout";
import Footer from "../../components/Footer/Footer";
import UnderDevelopment from "../UnderDevelopment";

function TwoFiveGallonLid() {
  return (
    <div>
      <UnderDevelopment></UnderDevelopment>
      <Footer></Footer>
    </div>
  );
}

export default TwoFiveGallonLid;
