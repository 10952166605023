import { createTheme } from "@mui/material/styles";

export const Colors = {
  maroon: "#880015",
  black: "#000000",
  white: "#FFFFFF",
  light_gray: "#C3C3C3",
  dark_gray: "#7F7F7F",
};

const CustomTheme = createTheme({
  palette: {
    maroon: {
      main: Colors.maroon,
    },
    primary: {
      main: Colors.light_gray,
    },
    secondary: {
      main: Colors.maroon,
    },
  },
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "lightblue",
    },
  },
});

export default CustomTheme;
