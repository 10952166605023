import { Card, Typography, CardHeader, CardContent } from "@mui/material";
import React from "react";
import { Colors } from "../theme/CustomTheme";

function AntiInnovation() {
  const centerDiv = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    paddingTop: "2%",
    paddingBottom: "2%",
    background: Colors.light_gray,
    minHeight: "95vh",
  };

  return (
    <>
      <div style={centerDiv}>
        <Card
          sx={{
            maxWidth: 600,
          }}
        >
          <CardHeader
            title={
              <Typography variant="h3" sx={{ color: Colors.black }}>
                The Anti-Innovator Repository
              </Typography>
            }
          />
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              What is Anti-Innovation?
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              Anti-innovation is behavior that is against innovation and/or
              behavior that discourages others from engaging in innovative.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              Anti-innovation can take many forms. Anti-innovation can be patent
              infringment, copying, stealing STL files, selling stolen
              intellectual property, stealing software, and/or violating user
              agreements.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              If you are the victim on an anti-innovator, it is likely that you
              are not the only one. You were likely not the first victim too.
              Anti-innovators are often habitual and utilize unethical tactics.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              We believe the future of humankind lies in those who are capable
              of crafting solutions to realized problems. We believe that the
              true damage that anti-innovators inflict is in discouraging
              innovators from sharing their solutions with the world.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              At Nephew Technologies, we want to build a world where creativity,
              invention, and problem solving is rewarded and not stolen. We want
              innovators to share their new solutions or processes with the
              world. In so doing, we will manifest a better world.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              Here at Nephew Technologies LLC, we want to provide the community
              with tools for identifying anti-innovators. We want to push back
              on dishonest and malicious actors. We are working to create a
              public repository of verified experiences with anti-innovators. We
              want to identify anti-innovators and provide a platform for
              warning others of their poor reputation.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h6" sx={{ color: Colors.black }}>
              If you are in contact with someone who wants to work with you, buy
              your design, has a confounding proposition, or is requesting a
              partnership -- the NT Anti-Innovator Repository will be your first
              stop in your decision making process. This tool will rely on the
              community to submit details and evidence of anti-innovative
              behavior.
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default AntiInnovation;
