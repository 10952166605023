import React, { useState } from "react";
import "../../App.css";
// import { Button } from "../Button/Button";
import "./TopHomeSection.css";
import { Button } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import NotificationModal from "../Modals/UnderDevelopmentModal";
import { Typography } from "@mui/material";
import Video from "../../videos/hpv.mp4";
import Slideshow from "../Slideshow/Slideshow";

function TopHomeSection() {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
    console.log(modal);
  };
  // images/5-5Gal001.jpg
  // <video src="./videos/hpv.mp4" autoPlay loop muted />

  /*
<video autoPlay loop muted>
        <source src={Video} type="video/mp4"></source>
      </video>
  */

  /*
  return (
    <div className="home-container">
      {modal ? (
        <UnderDevelopmentModal modal={modal} setModal={setModal} />
      ) : (
        <></>
      )}
      <Typography variant="h4" color="primary">
        Modernizing Pet Ownership
      </Typography>
      <Typography variant="h5" color="primary">
        Are you ready?
      </Typography>
      <div className="home-btns">
        <Button
          component={NavLink}
          to="/our-vision"
          sx={{ margin: 1 }}
          color="primary"
          variant="outlined"
          size="small"
        >
          ABOUT US
        </Button>

        <Button
          onClick={toggleModal}
          variant="outlined"
          size="small"
          color="primary"
        >
          WATCH TRAILER <i className="far fa-play-circle" />
        </Button>
      </div>
    </div>
  ); */
  return <Slideshow></Slideshow>;
}
{
  /* component={Link} */
}
export default TopHomeSection;
