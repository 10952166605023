import React, { useState } from "react";
import "./ProductCards.css";
import CardItem from "../CardItem/CardItem";
import InventoryIcon from "@mui/icons-material/Inventory";
import { Button, Typography } from "@mui/material";
import { Colors } from "../../theme/CustomTheme";
import ProductCard from "../ProductCard/ProductCard";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

import {
  PetViewerM1_data,
  VineHolder_data,
  SingleMicroFeeder_data,
  DoubleMicroFeeder_data,
  TwoFiveGallonLid_data,
  FiveFiveGallonLid_data,
  PetViewerM2_data,
  Bowl_data,
} from "../../data/Data";

function ProductCards() {
  const [filter, setFilter] = useState(false);

  const alignTextCenter = {
    textAlign: "center",
  };

  const backgroundColor = {
    backgroundColor: Colors.dark_gray,
  };

  const handleFilter = () => {
    setFilter(!filter);
  };

  {
    /* <Typography variant="h4" color="secondary" sx={alignTextCenter}>
          <InventoryIcon />
          Products
        </Typography> */
  }

  return (
    <div className="cards" style={backgroundColor}>
      <div style={alignTextCenter}>
        <Button variant="contained" onClick={handleFilter} color="secondary">
          {filter ? <FilterListIcon /> : <FilterListOffIcon />}
          Filter
        </Button>
      </div>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="product_cards">
            <div className="product_card">
              <ProductCard data={PetViewerM1_data} />
            </div>
            <div className="product_card">
              <ProductCard data={PetViewerM2_data} />
            </div>
            <div className="product_card">
              <ProductCard data={SingleMicroFeeder_data} />
            </div>
          </ul>
          <ul className="product_cards">
            <div className="product_card">
              <ProductCard data={DoubleMicroFeeder_data} />
            </div>
            <div className="product_card">
              <ProductCard data={TwoFiveGallonLid_data} />
            </div>
            <div className="product_card">
              <ProductCard data={FiveFiveGallonLid_data} />
            </div>
          </ul>
          <ul className="product_cards">
            <div className="product_card">
              <ProductCard data={VineHolder_data} />
            </div>
            <div className="product_card">
              <ProductCard data={Bowl_data} />
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProductCards;
