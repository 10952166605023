import React, { useState, useEffect } from "react";
// import { Button } from "../Button/Button";
import { Link } from "react-router-dom";
import "./Navbar.css";
import ScienceTwoToneIcon from "@mui/icons-material/ScienceTwoTone";
import Menu from "./Menu";
import Dashboard from "./Dashboard";

function Navbar() {
  return (
    <>
      <nav className="navbar">
        <div className="navbar-items">
          <div className="navbar-item padding-left">
            <Menu />
          </div>
          <div className="navbar-item padding-right">
            <Dashboard />
          </div>
        </div>
      </nav>
    </>
  );
}
// AccountCircleIcon

export default Navbar;

/*

const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);



<Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <ScienceTwoToneIcon />
            <Typography variant="h4" color="primary">
              NT
            </Typography>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                <Typography variant="h6" color="primary">
                  Home
                </Typography>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/services"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <Typography variant="h6" color="primary">
                  Services
                </Typography>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/products"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <Button>Products</Button>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/contact-us"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <Typography variant="h6" color="primary">
                  Contact Us
                </Typography>
              </Link>
            </li>

            <li>
              <Link
                to="/"
                className="nav-links-mobile"
                onClick={closeMobileMenu}
              >
                Close
              </Link>
            </li>
          </ul>
*/
