import * as React from "react";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import HomeIcon from "@mui/icons-material/Home";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import InventoryIcon from "@mui/icons-material/Inventory";
import MenuIcon from "@mui/icons-material/Menu";
import WarningIcon from "@mui/icons-material/Warning";
import CollectionsIcon from "@mui/icons-material/Collections";
import AntiInnovation from "../../pages/AntiInnovation";

export default function Menu() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 700;
  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (width > breakpoint) {
    return (
      <>
        <Button component={NavLink} to="/">
          Home
        </Button>
        <Button component={NavLink} to="/services">
          Services
        </Button>
        <Button component={NavLink} to="/products">
          Products
        </Button>
        <Button component={NavLink} to="/gallery">
          Gallery
        </Button>
        <Button component={NavLink} to="/contact-us">
          Contact
        </Button>
        <Button component={NavLink} to="/anti-innovation">
          Anti-Innovation
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Stack direction="row" spacing={2}>
          <div>
            <Button
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <MenuIcon />
              Menu
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          onClick={handleClose}
                          component={NavLink}
                          to="/"
                        >
                          <HomeIcon />
                          Home
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={NavLink}
                          to="/services"
                        >
                          <DesignServicesIcon />
                          Services
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={NavLink}
                          to="/products"
                        >
                          <InventoryIcon />
                          Products
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={NavLink}
                          to="/gallery"
                        >
                          <CollectionsIcon />
                          Gallery
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={NavLink}
                          to="/contact-us"
                        >
                          <ContactSupportIcon />
                          Contact Us
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={NavLink}
                          to="/anti-innovation"
                        >
                          <WarningIcon />
                          Anti-Innovation
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Stack>
      </>
    );
  }
}
