import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Tooltip } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Description from "../Description/Description";
import Features from "../Features/Features";
import { Link, NavLink } from "react-router-dom";
import { Colors } from "../../theme/CustomTheme";
import BuildIcon from "@mui/icons-material/Build";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProductCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        action={
          <IconButton aria-label="settings">
            <Tooltip title="Ask about this">
              <QuestionMarkIcon color="secondary" />
            </Tooltip>
          </IconButton>
        }
        title={
          <Typography variant="h5" sx={{ color: Colors.black }}>
            {props.data.name}
          </Typography>
        }
        subheader={
          <>
            <Tooltip title={props.data.developmentDescription}>
              <Typography variant="body2" sx={{ color: Colors.black }}>
                {"Status: " + props.data.status}
              </Typography>
            </Tooltip>
            <Tooltip title="The degree of technology utilized in this product.">
              <Typography variant="body2" sx={{ color: Colors.black }}>
                {"Tech Level: " + props.data.techLevel}
              </Typography>
            </Tooltip>
          </>
        }
      />
      {props.data.finished ? (
        <CardMedia
          component="img"
          height="194"
          image={props.data.mainImage}
          alt="Failed to load image"
        />
      ) : (
        <CardMedia
          component="img"
          height="194"
          image="images/gear.png"
          alt="Failed to load image"
        />
      )}

      <CardContent>
        <Typography variant="body" sx={{ color: Colors.black }}>
          {props.data.summary}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {props.data.finished ? (
          <IconButton aria-label="begin">
            <Tooltip title={props.data.tooltip}>
              <Link to={props.data.orderURL}>
                <AddBoxIcon color="secondary" />
              </Link>
            </Tooltip>
          </IconButton>
        ) : (
          <></>
        )}
        <IconButton aria-label="share">
          <Tooltip title="Share">
            <ShareIcon
              color="secondary"
              onClick={() => {
                navigator.clipboard.writeText(props.data.shareURL);
              }}
            />
          </Tooltip>
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Show more"
          color="secondary"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h6" sx={{ color: Colors.black }}>
            Description
          </Typography>
          <Description description={props.data.description} />
          <Typography variant="h6" sx={{ color: Colors.black }}>
            Features
          </Typography>
          <Features features={props.data.features} />
        </CardContent>
      </Collapse>
    </Card>
  );
}
