import React from "react";
import "../App.css";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";

export default function ContactUs() {
  return (
    <>
      <Contact></Contact>
      <Footer></Footer>
    </>
  );
}
/*
<UnderDevelopment></UnderDevelopment>
      <h1 className="contact-us">Contact us page goes here</h1>
*/
