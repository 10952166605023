import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  MenuItem,
  Typography,
} from "@mui/material";
import { Colors } from "../../theme/CustomTheme";
import { PetType } from "../../data/PetData";
import { sendEmail, test } from "../../axios/EmailAPI";
import NotificationModal from "../Modals/UnderDevelopmentModal";
import { useAuth } from "../../security/AuthProvider";

export default function Contact() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [motivation, setMotivation] = useState("");
  const [message, setMessage] = useState("");
  const [petType, setPetType] = useState("");
  const [subject, setSubject] = useState("");

  const [notificationModalTitle, setNotificationModalTitle] = useState("");
  const [notificationModalDescription, setNotificationModalDescription] =
    useState("");

  const [emailIsValid, setEmailIsValid] = useState(true);
  const [nameIsValid, setNameIsValid] = useState(true);
  const [motivationIsValid, setMotivationIsValid] = useState(true);
  const [messageIsValid, setMessageIsValid] = useState(true);
  const [petTypeIsValid, setPetTypeIsValid] = useState(true);
  const [subjectIsValid, setSubjectIsValid] = useState(true);
  const [initialValidation, setInitialValidation] = useState(false);

  const authContext = useAuth();

  const [emailSent, setEmailSent] = useState(false);

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
    console.log(modal);
  };

  const reasonsForContact = [
    { value: "Product Inquiry" },
    { value: "Product Idea" },
    { value: "Report a Problem" },
    { value: "Ask a Question" },
    { value: "Gallery Submission" },
  ];

  const style = {
    minHeight: "100vh",
    paddingTop: "3%",
    paddingBottom: "3%",
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    backgroundColor: Colors.dark_gray,
  };

  const center = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  };

  const validateEmail = (email) => {
    setEmailIsValid(authContext.validateEmail(email));
  };

  const validateInputLength = (string, length) => {
    if (string.length > length) {
      return true;
    }
    return false;
  };

  const validateName = (name) => {
    if (name) {
      setNameIsValid(validateInputLength(name, 3));
    } else {
      setNameIsValid(false);
    }
  };

  const validateMotivation = (motivation) => {
    if (motivation) {
      setMotivationIsValid(true);
    } else {
      setMotivationIsValid(false);
    }
  };

  const validatePetType = (pet) => {
    if (pet) {
      setPetTypeIsValid(true);
    } else {
      setPetTypeIsValid(false);
    }
  };

  const validateSubject = (subject) => {
    if (subject) {
      setSubjectIsValid(validateInputLength(subject, 10));
    } else {
      setSubjectIsValid(false);
    }
  };

  const validateContent = (content) => {
    if (content) {
      setMessageIsValid(validateInputLength(content, 30));
    } else {
      setMessageIsValid(false);
    }
  };

  const initialValidationCheck = (initialValidation) => {
    setInitialValidation(initialValidation);
  };

  const alertLocalVariables = () => {
    alert(
      "Email is " +
        emailIsValid +
        " Name is " +
        nameIsValid +
        " Motivation is " +
        motivationIsValid +
        " Content is " +
        messageIsValid +
        " InitialValidation is " +
        initialValidation
    );
  };
  /*
  useEffect(() => {
    alert("This page is still in active development.");
  }, []); */

  useEffect(() => {
    initialValidationCheck(true);
    validateEmail(email);
    validateName(name);
    validateMotivation(motivation);
    validatePetType(petType);
    validateContent(message);
    validateSubject(subject);
  });

  const handleClick = () => {
    // initialValidationCheck(initialValidation);
    validateEmail(email);
    validateName(name);
    validateMotivation(motivation);
    validatePetType(petType);
    validateContent(message);
    validateSubject(subject);

    if (
      petTypeIsValid &&
      emailIsValid &&
      nameIsValid &&
      motivationIsValid &&
      messageIsValid &&
      subjectIsValid
    ) {
      /*
      const sendThisEmail = {
        to: "nephewtechnologies@gmail.com",
        from: "nephewtechnologies@gmail.com",
        subject: motivation + " from " + name,
        html: `<p><strong>Name:</strong> ${name} </p>
        <p><strong>Email:</strong> ${email} </p>
        <p><strong>Pet Type:</strong> ${petType} </p>
        <p>${message}</p>`,
      }; */
      const emailObject = {
        name: name,
        email: email,
        motivation: motivation,
        petType: petType,
        message: message,
        subject: subject,
      };
      sendEmail(emailObject).then((res) => {
        if (res.status === 200) {
          setNotificationModalTitle("Thank you for contacting us.");
          setNotificationModalDescription(
            "We will respond to your inquiry shortly."
          );
          setModal(true);
          clearInputFields();
        } else {
          setNotificationModalTitle("There was a problem sending your email.");
          setNotificationModalDescription(
            "We apologize. Please try again later."
          );
          setModal(true);
        }
      });
    }
    /*
    test().then((res) => {
      alert(res);
    });
    */
  };

  const clearInputFields = () => {
    setMessage("");
  };

  const handleChange = (event) => {
    if (event.target.name === "name") {
      setName(event.target.value);
    } else if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "content") {
      setMessage(event.target.value);
    } else if (event.target.name === "motivation") {
      setMotivation(event.target.value);
    } else if (event.target.name === "pettype") {
      setPetType(event.target.value);
    } else if (event.target.name === "subject") {
      setSubject(event.target.value);
    }
    initialValidationCheck(true);
  };

  return (
    <div style={style}>
      <Box width="400px" component="form" autoComplete="off">
        <Card>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              align="center"
              sx={{ color: Colors.black }}
            >
              Contact Us
            </Typography>
          </CardContent>
          <div>
            <CardContent>
              {nameIsValid ? (
                <TextField
                  required
                  id="name"
                  label="Name"
                  variant="outlined"
                  type="text"
                  name="name"
                  fullWidth
                  onChange={handleChange}
                  size="small"
                  inputProps={{ autoComplete: "off" }}
                  autoComplete="off"
                  defaultValue={name}
                />
              ) : (
                <TextField
                  required
                  error
                  id="name"
                  label="Enter name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  name="name"
                  onChange={handleChange}
                  size="small"
                  inputProps={{ autoComplete: "off" }}
                  autoComplete="off"
                  defaultValue={name}
                />
              )}
            </CardContent>

            <CardContent>
              {subjectIsValid ? (
                <TextField
                  required
                  id="subject"
                  label="Subject"
                  variant="outlined"
                  type="text"
                  name="subject"
                  fullWidth
                  onChange={handleChange}
                  size="small"
                  inputProps={{ autoComplete: "off" }}
                  autoComplete="off"
                  defaultValue={subject}
                />
              ) : (
                <TextField
                  required
                  error
                  id="subject"
                  label="Enter subject"
                  variant="outlined"
                  type="text"
                  fullWidth
                  name="subject"
                  onChange={handleChange}
                  size="small"
                  inputProps={{ autoComplete: "off" }}
                  autoComplete="off"
                  defaultValue={subject}
                />
              )}
            </CardContent>

            <CardContent>
              {emailIsValid ? (
                <TextField
                  required
                  id="outlined-basic"
                  label="Email Address"
                  variant="outlined"
                  type="email"
                  name="email"
                  fullWidth
                  onChange={handleChange}
                  size="small"
                  defaultValue={email}
                />
              ) : (
                <TextField
                  required
                  error
                  id="outlined-basic"
                  label="Enter email address"
                  variant="outlined"
                  type="email"
                  fullWidth
                  name="email"
                  onChange={handleChange}
                  size="small"
                  defaultValue={email}
                />
              )}
            </CardContent>

            <CardContent>
              {motivationIsValid ? (
                <TextField
                  required
                  id="outlined-basic"
                  label="Motivation"
                  variant="outlined"
                  select
                  name="motivation"
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  value={motivation}
                  helperText="Select the reason for this email"
                >
                  {reasonsForContact.map((reason) => (
                    <MenuItem key={reason.value} value={reason.value}>
                      {reason.value}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  required
                  error
                  id="outlined-basic"
                  label="Select Motivation"
                  variant="outlined"
                  select
                  fullWidth
                  name="motivation"
                  onChange={handleChange}
                  size="small"
                  value={motivation}
                  helperText="Select the reason for this email"
                >
                  {reasonsForContact.map((reason) => (
                    <MenuItem key={reason.value} value={reason.value}>
                      {reason.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </CardContent>
            <CardContent>
              {petTypeIsValid ? (
                <TextField
                  required
                  id="outlined-basic"
                  label="Pet Type"
                  variant="outlined"
                  select
                  name="pettype"
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  value={petType}
                  helperText="Select the type of pet"
                >
                  {PetType.map((pet) => (
                    <MenuItem key={pet.value} value={pet.value}>
                      {pet.value}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  required
                  error
                  id="outlined-basic"
                  label="Select Pet Type"
                  variant="outlined"
                  select
                  fullWidth
                  name="pettype"
                  onChange={handleChange}
                  size="small"
                  value={petType}
                  helperText="Select the type of pet"
                >
                  {PetType.map((pet) => (
                    <MenuItem key={pet.value} value={pet.value}>
                      {pet.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </CardContent>
          </div>
          <CardContent>
            {messageIsValid ? (
              <TextField
                id="filled-multiline-flexible"
                label="Message"
                multiline
                name="content"
                maxRows={4}
                minRows={4}
                value={message}
                onChange={handleChange}
                variant="filled"
                fullWidth
              />
            ) : (
              <TextField
                id="filled-multiline-flexible"
                label="Please enter your message"
                multiline
                error
                name="content"
                maxRows={4}
                minRows={4}
                value={message}
                onChange={handleChange}
                variant="filled"
                fullWidth
              />
            )}
          </CardContent>
          <div style={center}>
            <CardActions>
              <Button
                onClick={handleClick}
                size="large"
                variant="outlined"
                color="secondary"
              >
                Send Email
              </Button>
            </CardActions>
          </div>
        </Card>
        <NotificationModal
          modal={modal}
          setModal={setModal}
          title={notificationModalTitle}
          description={notificationModalDescription}
        />
      </Box>
    </div>
  );
}
