import React from "react";
import "../App.css";
import LoginComponent from "../components/LoginComponent/LoginComponent";
import Footer from "../components/Footer/Footer";
import { Colors } from "../theme/CustomTheme";

export default function Login() {
  const styles = {
    backgroundColor: Colors.light_gray,
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      <div style={styles}>
        <LoginComponent></LoginComponent>
      </div>
      <Footer></Footer>
    </>
  );
}
