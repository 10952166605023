export const PetViewerM1_data = {
  name: "PetViewer Terrestrial",
  status: "Developed",
  techLevel: "Low",
  finished: true,
  orderURL: "/order/petviewer-m1",
  developmentDescription:
    "This product was developed over a 5 month duration with animal trials.",
  summary:
    "This is the terrestrial model for invertebrates and small reptiles.",
  tooltip: "Build your PetViewer Terrestrial",
  shareURL: "nephewtechnologies.com/products",
  description: [
    {
      p: "Often our awesome little creatures prefer a life of privacy, darkness, and seclusion. Shouldn't there be a way to fulfill the needs of your little creature while also allowing it to be viewable?",
    },
    {
      p: "The PetViewer Terrestrial was developed to be a compromise for both us and our pets. Providing warmth, darkness, and safety to our pets -- and allowing our pets to be viewable.",
    },
    {
      p: "It provides comfort and safety for the creature it houses and it allows the pet to be viewable at any moment.",
    },
    {
      p: "With the help of inserts the interior can be resized to provide a lifelong home over the various stages growth during your pet's lifecycle.",
    },
  ],
  features: [
    {
      feature:
        "A heated insulated lid that can be easily removed. It will heat the interior area to about 90F after 30 minutes and drops to 80F in the back of the PetViewer. This allows your pet to self-regulate their temperature based on their immediate needs.",
    },
    {
      feature:
        "Powerful magnets that keep the PetViewer secured to the enclosure. The magnets are strong enough to adhere the PetViewer to the wall of the glass without need for any additional support. So there is no chance that your pet will accidentally crush itself if it chooses to dig under the PetViewer.",
    },
    {
      feature:
        "Two inserts that allow for different interior sizes during the various stages of your pet's growth and development. Or a single insert with a variety of possible interior designs.",
    },
  ],
  dimensions:
    "The entrance of the shell is 1 3/4 inches by 2 1/4 inches. The dimensions of large insert entrance is 1 5/8 inches tall and 1 3/4 inches wide. The dimensions of the small insert entrance is 7/8 inches tall and 1 5/8 inches wide. \n I am able to provide custom entrance sizing upon request as well as custom inserts.",
  tiktok:
    "Check out my TikTok for videos of the different PetViewer prototypes in application: @nephewtechnologies",
  questions: [
    {
      question: "Which kind of pet is this for?",
      answer:
        "Invertebrates and small reptiles. It can comfortably house adult emperor scorpions and tarantulas that have a 5-6 inch leg span (have not tested with larger tarantula species). I'd recommend it for smaller lizards too.",
    },
    {
      question: "What kind of insert would be best for my pet?",
      answer:
        "From my testing with emperor scorpions they prefer confined and dry substrate interiors.  My tarantula prefers an open interior with no substrate in the interior.",
    },
    {
      question: "How hot does the heated lid get? ",
      answer:
        "The heated lid is insulated on the exterior. The insulation reflects the heat back towards the enclosure. The surface of the interior glass can reach around 100F. The PetViewer interior will reach around 90F in temperature. My scorpions will sit next to the glass to warm up and will move to away from the glass to cool off. I recommend using the heated lid with a timer that runs every other hour during the day time. ",
    },
    {
      question: "How can I increase the humidity in the PetViewer? ",
      answer:
        "The plastic is not actually air tight. You can spray water on the top of the shell and it will slowly drip down into the interior of the PetViewer. The heated lid will begin evaporating the water increasing humidity both inside the PetViewer and inside the enclosure itself.",
    },
    {
      question: "Is it difficult to clean? ",
      answer:
        "No, not at all. Simply wash it with warm water. Don't use any cleaning chemicals that could be potentially dangerous to your pet. It will take around 4-5 hours to air dry.",
    },
  ],
  video: "/products/petviewer-m1-video",
  mainImage: "images/PetViewerM1-001.jpg",
  images: [
    {
      url: "../images/PetViewer-M1/PetViewer_M1_004.JPG",
      title: "Image 4",
    },
    {
      url: "http://localhost:3000/images/PetViewer-M1/PetViewer_M1_001.JPG",
      title: "Image 1",
    },
    {
      url: "http://localhost:3000/images/PetViewer-M1/PetViewer_M1_002.JPG",
      title: "Image 2",
    },
    {
      url: "http://localhost:3000/images/PetViewer-M1/PetViewer_M1_003.JPG",
      title: "Image 3",
    },
    {
      url: "http://localhost:3000/images/PetViewer-M1/005.JPG",
      title: "Image 5",
    },
  ],
  productWeight: 0,
  shippingWeight: 0,
};

export const VineHolder_data = {
  name: "Vine Holder",
  finished: true,
  status: "Developed",
  techLevel: "Low",
  orderURL: "/order/vine-holder",
  developmentDescription: "This product was developed over 2-3 day duration.",
  summary:
    "This is a great way to get your artificial vines under control and secured to a specific side of your enclosure.",
  tooltip: "Add to cart",
  shareURL: "nephewtechnologies.com/products",
  description: [
    {
      p: "Are you tired of having your foliage spread throughout your enclosure? The purpose of this design is to get artificial foliage under control without the need of silcone.",
    },
    {
      p: "The purpose of this design is to get artificial foliage under control without the need of silcone or a semi-permanent adhesive.",
    },
    {
      p: "This works great inside 2.5, 5.5, 10, and 20 gallon tanks.",
    },
  ],
  features: [
    {
      feature: "Easy and quick to install and remove.",
    },
    {
      feature: "Powerful magnets that secure the foliage to the enclosure.",
    },
    {
      feature: "8 feet of artifical vine is included.",
    },
  ],
  dimensions: "",
  tiktok: "",
  questions: [
    {
      question: "",
      answer: "",
    },
  ],
  video: "",
  mainImage: "images/VineHolder-001.jpg",
  images: [
    {
      url: "",
      title: "",
    },
  ],
  productWeight: 0,
  shippingWeight: 0,
};

export const SingleMicroFeeder_data = {
  name: "Single Micro Feeder",
  finished: true,
  status: "Developed",
  techLevel: "Low",
  orderURL: "/order/single-micro-feeder",
  developmentDescription: "This product was developed over 2-3 day duration.",
  summary:
    "Provide a compact dry food source for your feeders: crickets, cockroaches, etc. A quarter is included in the image as a point of reference for size determination.",
  tooltip: "Add to cart",
  shareURL: "nephewtechnologies.com/products",
  description: [
    {
      p: "This is a simple gravity controlled feeder. A good option for anyone who wants to reduce the need for adding small amounts of dry food for their feeders.",
    },
  ],
  features: [
    {
      feature: "Easy to clean.",
    },
    {
      feature:
        "The wide base design provides a good center of gravity to prevent the feeder from tipping over.",
    },
  ],
  dimensions: "",
  tiktok: "",
  questions: [
    {
      question: "",
      answer: "",
    },
  ],
  video: "",
  mainImage: "images/MicroSingleFeeder.jpg",
  images: [
    {
      url: "",
      title: "",
    },
  ],
  productWeight: 0,
  shippingWeight: 0,
};

export const DoubleMicroFeeder_data = {
  name: "Double Micro Feeder",
  finished: true,
  status: "Developed",
  techLevel: "Low",
  orderURL: "/order/double-micro-feeder",
  developmentDescription: "This product was developed over 2-3 day duration.",
  summary:
    "Provide a compact dry food source for your feeders: crickets, cockroaches, etc. A quarter is included in the image as a point of reference for size determination.",
  tooltip: "Add to cart",
  shareURL: "nephewtechnologies.com/products",
  description: [
    {
      p: "This is a simple gravity controlled feeder. A good option for anyone who wants to reduce the need for adding small amounts of dry food for their feeders.",
    },
  ],
  features: [
    {
      feature: "Easy to clean.",
    },
    {
      feature:
        "The wide base design provides a good center of gravity to prevent the feeder from tipping over.",
    },
  ],
  dimensions: "",
  tiktok: "",
  questions: [
    {
      question: "",
      answer: "",
    },
  ],
  video: "",
  mainImage: "images/MicroDoubleFeeder.jpg",
  images: [
    {
      url: "",
      title: "",
    },
  ],
  productWeight: 0,
  shippingWeight: 0,
};

export const TwoFiveGallonLid_data = {
  name: "2.5 Gallon Aquarium Lid",
  finished: true,
  status: "Developed",
  techLevel: "Low",
  orderURL: "/order/2-5-gallon-lid",
  developmentDescription: "This product was developed over 3 week duration.",
  summary:
    "A secure lid for a 2.5 gallon Aqueon aquarium. The lid supports light bar options and control inserts for interior humidity retention and loss.",
  tooltip: "Build you 2.5 Gallon Tank Lid",
  shareURL: "nephewtechnologies.com/products",
  description: [
    {
      p: "This product was developed due to the lack of quality options for aquariums when used in non-aquatic setups.",
    },
    {
      p: "The tank allows for excellent interior humidity control through the use of magnetized inserts. There is also a selection of vibrant LEDs that will make your setup a beautiful center piece in a low light room.",
    },
  ],
  features: [
    {
      feature: "All moving components are securely magnetized.",
    },
    {
      feature: "Sliding door to provide easy access to the interior.",
    },
    {
      feature: "Humidity control inserts.",
    },
    {
      feature: "A selection of LED lights.",
    },
    {
      feature: "Moving components that are quick and easy to swap out.",
    },
    {
      feature:
        "Modular moving components that are compadible with all NT aquarium lids.",
    },
  ],
  dimensions: "",
  tiktok: "",
  questions: [
    {
      question: "",
      answer: "",
    },
  ],
  video: "",
  mainImage: "images/2-5Gal001.jpg",
  images: [
    {
      url: "",
      title: "",
    },
  ],
  productWeight: 0,
  shippingWeight: 0,
};

export const FiveFiveGallonLid_data = {
  name: "5.5 Gallon Aquarium Lid",
  finished: true,
  status: "Developed",
  techLevel: "Low",
  orderURL: "/order/5-5-gallon-lid",
  developmentDescription: "This product was developed over 1 week duration.",
  summary:
    "A secure lid for a 5.5 gallon Aqueon aquarium. The lid supports light bar options and control inserts for interior humidity retention and loss.",
  tooltip: "Build you 5.5 Gallon Tank Lid",
  shareURL: "nephewtechnologies.com/products",
  description: [
    {
      p: "This product was developed due to the lack of quality options for aquariums when used in non-aquatic setups.",
    },
    {
      p: "The tank allows for excellent interior humidity control through the use of magnetized inserts. There is also a selection of vibrant LEDs that will make your setup a beautiful center piece in a low light room.",
    },
  ],
  features: [
    {
      feature: "All moving components are securely magnetized.",
    },
    {
      feature: "Sliding door to provide easy access to the interior.",
    },
    {
      feature: "Humidity control inserts.",
    },
    {
      feature: "A selection of LED lights.",
    },
    {
      feature: "Moving components that are quick and easy to swap out.",
    },
    {
      feature:
        "Modular moving components that are compadible with all NT aquarium lids.",
    },
  ],
  dimensions: "",
  tiktok: "",
  questions: [
    {
      question: "",
      answer: "",
    },
  ],
  video: "",
  mainImage: "images/5-5Gal001.jpg",
  images: [
    {
      url: "",
      title: "",
    },
  ],
  productWeight: 0,
  shippingWeight: 0,
};

// In Development Below

export const PetViewerM2_data = {
  name: "PetViewer Arboreal",
  finished: false,
  status: "In Development",
  techLevel: "Low",
  orderURL: "/order/petviewer-m2",
  developmentDescription: "This product is currently in development",
  summary: "This will be the arboreal PetViewer model for small pets.",
  tooltip: "Build your PetViewer Arboreal",
  shareURL: "nephewtechnologies.com/products",
  description: [
    {
      p: "Often our awesome little creatures prefer a life of privacy, darkness, and seclusion. Shouldn't there be a way to fulfill the needs of your little creature while also allowing it to be viewable?",
    },
    {
      p: "The PetViewer Arboreal is being developed to be a compromise for both us and our pets. Providing warmth, darkness, and safety to our pets -- and allowing our pets to be viewable.",
    },
    {
      p: "It will provide comfort and safety for the creature it houses and it will allow the pet to be viewable at any moment.",
    },
    {
      p: "With the help of inserts the interior will be able to be resized to provide a lifelong home over the various stages growth during your pet's lifecycle.",
    },
  ],
  features: [
    {
      feature:
        "A heated insulated lid that can be easily removed. It will heat the interior area to about 90F after 30 minutes and drops to 80F in the back of the PetViewer. This allows your pet to self-regulate their temperature based on their immediate needs.",
    },
    {
      feature:
        "Powerful magnets that keep the PetViewer secured to the enclosure. The magnets are strong enough to adhere the PetViewer to the wall of the glass without need for any additional support.",
    },
    {
      feature:
        "Two inserts that allow for different interior sizes during the various stages of your pet's growth and development. Or a single insert with a variety of possible interior designs.",
    },
  ],
  dimensions:
    "The entrance of the shell is 1 3/4 inches by 2 1/4 inches. The dimensions of large insert entrance is 1 5/8 inches tall and 1 3/4 inches wide. The dimensions of the small insert entrance is 7/8 inches tall and 1 5/8 inches wide. \n I am able to provide custom entrance sizing upon request as well as custom inserts.",
  tiktok:
    "Check out my TikTok for videos of the different PetViewer prototypes in application: @nephewtechnologies",
  questions: [
    {
      question: "Which kind of pet is this for?",
      answer:
        "Invertebrates and small reptiles. It can comfortably house adult emperor scorpions and tarantulas that have a 5-6 inch leg span (have not tested with larger tarantula species). I'd recommend it for smaller lizards too.",
    },
    {
      question: "What kind of insert would be best for my pet?",
      answer:
        "From my testing with emperor scorpions they prefer confined and dry substrate interiors.  My tarantula prefers an open interior with no substrate in the interior.",
    },
    {
      question: "How hot does the heated lid get? ",
      answer:
        "The heated lid is insulated on the exterior. The insulation reflects the heat back towards the enclosure. The surface of the interior glass can reach around 100F. The PetViewer interior will reach around 90F in temperature. My scorpions will sit next to the glass to warm up and will move to away from the glass to cool off. I recommend using the heated lid with a timer that runs every other hour during the day time. ",
    },
    {
      question: "How can I increase the humidity in the PetViewer? ",
      answer:
        "The plastic is not actually air tight. You can spray water on the top of the shell and it will slowly drip down into the interior of the PetViewer. The heated lid will begin evaporating the water increasing humidity both inside the PetViewer and inside the enclosure itself.",
    },
    {
      question: "Is it difficult to clean? ",
      answer:
        "No, not at all. Simply wash it with warm water. Don't use any cleaning chemicals that could be potentially dangerous to your pet. It will take around 4-5 hours to air dry.",
    },
  ],
  video: "/products/",
  mainImage: "images/",
  images: [
    {
      url: "../images/PetViewer-M2/PetViewer_M2_001.JPG",
      title: "Image 1",
    },
    {
      url: "http://localhost:3000/images/PetViewer-M2/PetViewer_M2_002.JPG",
      title: "Image 2",
    },
    {
      url: "http://localhost:3000/images/PetViewer-M2/PetViewer_M1_003.JPG",
      title: "Image 3",
    },
    {
      url: "http://localhost:3000/images/PetViewer-M2/PetViewer_M1_004.JPG",
      title: "Image 4",
    },
    {
      url: "http://localhost:3000/images/PetViewer-M2/005.JPG",
      title: "Image 5",
    },
  ],
  productWeight: 0,
  shippingWeight: 0,
};

export const Bowl_data = {
  name: "Bowl",
  finished: false,
  status: "In Development",
  techLevel: "Low",
  orderURL: "",
  developmentDescription: "This product is currently in development",
  summary:
    "This will be an easy to clean bowl with a variety of custom options during the build process.",
  tooltip: "Build your Bowl",
  shareURL: "nephewtechnologies.com/products",
  description: [
    {
      p: "This will be a shallow bowl that will be magnetically housed. The housing will have the option to include side mounting magnets.",
    },
  ],
  features: [
    {
      feature: "Shallow bowl.",
    },
    {
      feature: "Easy to clean.",
    },
    {
      feature: "Usable for water and food.",
    },
  ],
  dimensions: "",
  tiktok:
    "Check out my TikTok for videos of the different PetViewer prototypes in application: @nephewtechnologies",
  questions: [
    {
      question: "Which kind of pet is this for?",
      answer: "Invertebrates and small reptiles.",
    },
  ],
  images: [
    {
      url: "",
      title: "Image 1",
    },
  ],
  productWeight: 0,
  shippingWeight: 0,
};

export const petViewerM1Ultimate_data = {
  name: "PetViewer M1 Ultimate",
  finished: false,
  status: "Under Development",
  techLevel: "High",
  orderURL: "/order/petviewer-m1",
  developmentDescription: "This product is currently under development.",
  summary:
    "A secure lid for an 5.5 gallon Aqueon aquarium. The lid supports light bar options and control inserts for interior humidity retention and loss.",
  tooltip: "Build you 5.5 Gallon Tank Lid",
  shareURL: "nephewtechnologies.com/products",
  description: [
    {
      p: "This product was developed due to the lack of quality options for aquariums when used in non-aquatic setups.",
    },
    {
      p: "The tank allows for excellent interior humidity control through the use of magnetized inserts. There is also a selection of vibrant LEDs that will make your setup a beautiful center piece in a low light room.",
    },
  ],
  features: [
    {
      feature: "All moving components are securely magnetized.",
    },
    {
      feature: "Sliding door to provide easy access to the interior.",
    },
    {
      feature: "Humidity control inserts.",
    },
    {
      feature: "A selection of LED lights.",
    },
    {
      feature: "Moving components that are quick and easy to swap out.",
    },
    {
      feature:
        "Modular moving components that are compadible with all NT aquarium lids.",
    },
  ],
  dimensions: "",
  tiktok: "",
  questions: [
    {
      question: "",
      answer: "",
    },
  ],
  video: "",
  mainImage: "images/5-5Gal001.jpg",
  images: [
    {
      url: "",
      title: "",
    },
  ],
  productWeight: 0,
  shippingWeight: 0,
};
