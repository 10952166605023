import React from "react";
import Footer from "../components/Footer/Footer";
import ImageGallery from "../components/ImageGallery/ImageGallery";

function PetGallery() {
  return (
    <div>
      <ImageGallery />
      <Footer></Footer>
    </div>
  );
}

export default PetGallery;
