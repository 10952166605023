import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";

function NotificationModal({ modal, setModal, title, description }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    alignItems: "left",
    flexDirection: "column",
  };

  const margin = {
    margin: "10px",
  };

  const handleClose = () => {
    setModal(!modal);
  };

  return (
    <div>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>
          <Button
            sx={margin}
            color="maroon"
            variant="outlined"
            size="large"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default NotificationModal;
