import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Colors } from "../../theme/CustomTheme";
import { TextField, Button, Typography } from "@mui/material";
import { useAuth } from "../../security/AuthProvider";

export default function LoginComponent() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [message, setMessage] = useState("");
  const authContext = useAuth();

  const textFieldPadding = {
    padding: "0 0 10px 0",
  };

  const handleClick = () => {
    if (authContext.login(email, password)) {
      setMessage("Authentication has taken place.");
    } else {
      setMessage("Authentication has failed.");
    }
  };

  const onChange = (event) => {
    if (event.target.type === "password") {
      setPassword(event.target.value);
    } else if (event.target.type === "email") {
      setEmail(event.target.value);
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 400,
        backgroundColor: Colors.white,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={textFieldPadding}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {message}
          </Typography>
        </div>
        <div style={textFieldPadding}>
          <TextField
            required
            id="outlined-required"
            label="Email"
            variant="outlined"
            onChange={onChange}
            type="email"
            size="small"
          />
        </div>
        <div style={textFieldPadding}>
          <TextField
            required
            id="outlined-required"
            label="Password"
            variant="outlined"
            type="password"
            onChange={onChange}
            size="small"
          />
        </div>
        <div style={textFieldPadding}>
          <Button variant="contained" onClick={handleClick}>
            Login
          </Button>
        </div>
        <div style={textFieldPadding}>
          <Button variant="contained">Create Account</Button>
        </div>
      </CardContent>
    </Card>
  );
}
