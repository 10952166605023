import React from "react";
import Footer from "../components/Footer/Footer";
import { Typography } from "@mui/material";

function UnderDevelopment() {
  const style = {
    minHeight: "50vh",
    paddingTop: "25px",
  };

  return (
    <>
      <div style={style}>
        <Typography gutterBottom variant="h2" align="center" color="secondary">
          This page is currently under development.
        </Typography>
        <Typography gutterBottom variant="h3" align="center" color="secondary">
          Thank you for your understanding.
        </Typography>
      </div>
      <Footer></Footer>
    </>
  );
}

export default UnderDevelopment;
