import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

function Footer() {
  return (
    <div className="footer-container">
      <div class="footer-links">
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <Typography
              gutterBottom
              variant="h6"
              align="center"
              color="primary"
            >
              Contact Us
            </Typography>
            <Link to="/underdevelopment">
              <Typography gutterBottom variant="p" color="primary">
                Contact
              </Typography>
            </Link>
            <Link to="/underdevelopment">
              <Typography gutterBottom variant="p" color="primary">
                Support
              </Typography>
            </Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <Typography gutterBottom variant="h6" color="primary">
              Media Presence
            </Typography>
            <Link to="https://www.etsy.com/shop/nephewtechnologies">
              <Typography gutterBottom variant="p" color="primary">
                Etsy
              </Typography>
            </Link>
            <Link to="https://www.tiktok.com/@nephewtechnologies">
              <Typography gutterBottom variant="p" color="primary">
                TikTok
              </Typography>
            </Link>
            <Link to="https://www.youtube.com/@nephewtechnologies">
              <Typography gutterBottom variant="p" color="primary">
                Youtube
              </Typography>
            </Link>
            <Link to="https://www.instagram.com/nephewtechnologies/">
              <Typography gutterBottom variant="p" color="primary">
                Instagram
              </Typography>
            </Link>
          </div>
        </div>
      </div>
      <section class="social-media">
        <div class="social-media-wrap">
          <Link to="/" className="social-logo">
            {/*
              <div class="smaller-logo">
                <CardMedia
                  component="img"
                  height="35"
                  width="auto"
                  image="images/nt-logo-nobackground.png"
                  alt="Failed to load image"
                />
              </div>) 
            */}
            <Typography gutterBottom variant="p " color="primary">
              NEPHEW TECHNOLOGIES LLC
            </Typography>
          </Link>
          <small class="website-rights">
            <Typography gutterBottom variant="p" color="primary">
              NT LLC © 2022
            </Typography>
          </small>
          {/*
          <div class="social-icons">
            <Link
              class="social-icon-link facebook"
              to="/"
              target="_blank"
              aria-label="Facebook"
            >
              <i class="fab fa-facebook-f" />
            </Link>
            <Link
              class="social-icon-link instagram"
              to="/"
              target="_blank"
              aria-label="Instagram"
            >
              <i class="fab fa-instagram" />
            </Link>
            <Link
              class="social-icon-link youtube"
              to="/"
              target="_blank"
              aria-label="Youtube"
            >
              <i class="fab fa-youtube" />
            </Link>
            <Link
              class="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="Twitter"
            >
              <i class="fab fa-twitter" />
            </Link>
            <Link
              class="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i class="fab fa-linkedin" />
            </Link>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Footer;
